@font-face {
	font-family: 'Caveat-Regular';
	src: url('./Caveat-Regular.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Caveat-Bold';
	src: url('./Caveat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('./Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PFDisplay-Medium';
	src: url('./PlayfairDisplay-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PFDisplay-Bold';
	src: url('./PlayfairDisplay-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-ExtraLight';
	src: url('./Montserrat-ExtraLight.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-Light';
	src: url('./Montserrat-Light.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-Medium';
	src: url('./Montserrat-Medium.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'NotoSerif-Bold';
	src: url('./NotoSerif-Bold.ttf') format('truetype');
	font-style: normal;
}
