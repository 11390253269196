@import '~react-image-gallery/styles/css/image-gallery.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@layer utilities {
	.line-clamp-25 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 25;
		overflow: hidden;
	}
	.line-clamp-20 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 20;
		overflow: hidden;
	}
}
